import React, { useState } from "react";
import { db } from "../firebase";
import { collection, setDoc, doc } from "firebase/firestore";
import * as Papa from "papaparse";

const UploadRacers = () => {
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          setCsvData(results.data);
        },
      });
    }
  };

  const uploadRacersToFirestore = async () => {
    if (csvData.length === 0) {
      alert("No data to upload. Please select a valid CSV file.");
      return;
    }
    
    setLoading(true);
    try {
      for (const racer of csvData) {
        const racerId = racer["First Name"] + "_" + racer["Last Name"];
        if (!racer.Category || !racer["First Name"] || !racer["Last Name"]) {
          console.warn("Skipping invalid racer:", racer);
          continue;
        }

        const racerRef = doc(collection(db, "racers"), racerId);
        await setDoc(racerRef, {
          category: racer.Category,
          firstName: racer["First Name"],
          lastName: racer["Last Name"],
          picture: racer["Picture"],
          origin: racer.Origin || "",
          gameId: parseInt(racer["Game ID"], 10) || 5,
          rank2024: parseInt(racer.Rank2024, 10) || 0,
          totalPoint2024: parseInt(racer.TotalPoint2024, 10) || 0,
          price: parseInt(racer.Price, 10) || 75000,
        });
        console.log(`Uploaded: ${racer["First Name"]} ${racer["Last Name"]}`);
      }
      alert("✅ All racers successfully uploaded!");
    } catch (error) {
      console.error("❌ Error uploading racers:", error);
      alert("❌ Failed to upload racers. Check console for errors.");
    }
    setLoading(false);
  };

  return (
    <div className="upload-container">
      <h2>Upload Racers CSV</h2>
      <input type="file" accept=".csv" onChange={handleFileUpload} />
      
      {csvData.length > 0 && (
        <div>
          <h3>Preview ({csvData.length} racers found)</h3>
          <table border="1">
            <thead>
              <tr>
                <th>Category</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Origin</th>
                <th>Game ID</th>
                <th>Rank2024</th>
                <th>TotalPoint2024</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {csvData.slice(0, 5).map((racer, index) => (
                <tr key={index}>
                  <td>{racer.Category}</td>
                  <td>{racer["First Name"]}</td>
                  <td>{racer["Last Name"]}</td>
                  <td>{racer.Origin || "N/A"}</td>
                  <td>{racer["Game ID"]}</td>
                  <td>{racer.Rank2024}</td>
                  <td>{racer.TotalPoint2024}</td>
                  <td>${parseInt(racer.Price, 10).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <button onClick={uploadRacersToFirestore} disabled={loading || csvData.length === 0}>
        {loading ? "Uploading..." : "Upload Racers"}
      </button>
    </div>
  );
};

export default UploadRacers;