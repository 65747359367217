import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import Login from "../components/Login";
import { db } from "../firebase";
import { doc, getDoc, getDocs, collection, setDoc, updateDoc, arrayUnion,deleteDoc } from "firebase/firestore";
import gamesData from "../data/games";
import { useGame } from "../contexts/GameContext";
import { FaTrash, FaDoorOpen } from "react-icons/fa";
import "../App.css";
import MyTeam from "../components/MyTeam";

const HomePage = () => {
  const { user, login, signup } = useAuth(); // Auth context
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [team, setTeam] = useState([]);
  const [userRanking, setUserRanking] = useState(null);
  const { game, setGame } = useGame();
  const [userBank, setUserBank] = useState(null);
  const [username, setUsername] = useState(null);
  const [leagues, setLeagues] = useState([]);
  const [invitedEmails, setInvitedEmails] = useState([]);
  const [inviteInput, setInviteInput] = useState("");
  const [showLeagueModal, setShowLeagueModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [leagueName, setLeagueName] = useState("");
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [inviteEmail, setInviteEmail] = useState("");
  const { id } = useParams();


  const fetchLeagues = async () => {
    if (!user) return;
  
    try {
      //console.log("Fetching leagues for:", user.email);
  
      const leaguesSnapshot = await getDocs(collection(db, "leagues"));
      const allLeagues = leaguesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      //console.log("All leagues from Firestore:", allLeagues);
  
      const userLeagues = allLeagues.filter(league =>
        (league.members && Array.isArray(league.members) &&
          league.members.some(member => member.email === user.email)) // ✅ User is a member
        || league.owner === user.email // ✅ User is owner
        || (league.invited && league.invited.some(invite => invite.email === user.email)) // ✅ User is invited
      );
  
      //console.log("Filtered leagues for user:", userLeagues);
  
      setLeagues(userLeagues);
    } catch (error) {
      console.error("Error fetching leagues:", error);
    }
  };

  const handleAcceptInvite = async (leagueId) => {
    if (!user) return;
  
    const leagueRef = doc(db, "leagues", leagueId);
  
    try {
      const leagueDoc = await getDoc(leagueRef);
      if (!leagueDoc.exists()) return;
  
      const leagueData = leagueDoc.data();
      const updatedInvites = leagueData.invited.filter(invite => invite.email !== user.email);
      const newMember = { email: user.email, score: 0, status: "accepted" };
  
      await updateDoc(leagueRef, {
        invited: updatedInvites,
        members: arrayUnion(newMember),
      });
  
      fetchLeagues(); // Refresh UI
    } catch (error) {
      console.error("Error accepting invitation:", error);
    }
  };
  
  const handleLeaveLeague = async (leagueId) => {
    if (!user) return;
  
    const leagueRef = doc(db, "leagues", leagueId);
  
    try {
      const leagueDoc = await getDoc(leagueRef);
      if (!leagueDoc.exists()) return;
  
      const leagueData = leagueDoc.data();
      const updatedMembers = leagueData.members.filter(member => member.email !== user.email);
  
      await updateDoc(leagueRef, { members: updatedMembers });
  
      fetchLeagues(); // Refresh UI
    } catch (error) {
      console.error("Error leaving league:", error);
    }
  };

  const handleDeleteLeague = async (leagueId) => {
    if (!user) return;
  
    const leagueRef = doc(db, "leagues", leagueId);
  
    try {
      const leagueDoc = await getDoc(leagueRef);
      if (!leagueDoc.exists()) return;
  
      const leagueData = leagueDoc.data();
      if (leagueData.owner !== user.email) {
        console.warn("Only the owner can delete the league.");
        return;
      }
  
      await deleteDoc(leagueRef);
  
      fetchLeagues(); // Refresh UI
    } catch (error) {
      console.error("Error deleting league:", error);
    }
  };
  const handleDismissInvite = async (leagueId) => {
    if (!user) return;
  
    const leagueRef = doc(db, "leagues", leagueId);
  
    try {
      const leagueDoc = await getDoc(leagueRef);
      if (!leagueDoc.exists()) return;
  
      const leagueData = leagueDoc.data();
      const updatedInvites = leagueData.invited.filter(invite => invite.email !== user.email);
  
      await updateDoc(leagueRef, { invited: updatedInvites });
  
      fetchLeagues(); // Refresh UI
    } catch (error) {
      console.error("Error dismissing invitation:", error);
    }
  };

  useEffect(() => {
    if (game) {
      localStorage.setItem("gameId", game.id); // ✅ Save game ID to local storage
    }
  }, [game]);

  // ✅ Set game based on URL param
  useEffect(() => {
    if (!game) {
      const foundGame = gamesData.find(g => g.id === id);
      if (foundGame) {
        setGame(foundGame);
      } else {
        navigate("/"); // Redirect to splash if game is not found
      }
    }
  }, [game, id, setGame, navigate]);
  
  useEffect(() => {
    if (game) {
      localStorage.setItem("gameId", game.id); // ✅ Save game ID in local storage
    }
  }, [game]);

  // ✅ Ensure fetchLeagues runs after user changes
  useEffect(() => {
    fetchLeagues();
  }, [user]);

  // ✅ Handle "Create a League"
  const handleCreateLeague = async () => {
    if (!user || !leagueName.trim()) return;
  
    const leagueRef = doc(collection(db, "leagues"));
  
    const leagueData = {
      id: leagueRef.id,
      name: leagueName,
      owner: user.email,
      members: [{ email: user.email, score: 0, status: "accepted" }], // ✅ Ensure correct format
      invited: [],
      totalPoints: 0,
    };
  
    try {
      await setDoc(leagueRef, leagueData);
      setShowLeagueModal(false);
      setLeagueName("");
  
      // ✅ Immediately fetch leagues after creating one
      fetchLeagues();
    } catch (error) {
      console.error("Error creating league:", error);
    }
  };

  // ✅ Handle email invitations
  const handleInviteMember = async () => {
    if (!selectedLeague || !inviteEmail.trim()) return;
  
    const leagueRef = doc(db, "leagues", selectedLeague.id);
    try {
      await updateDoc(leagueRef, {
        invited: arrayUnion({ email: inviteEmail, status: "pending" }),
      });
      setShowInviteModal(false);
      setInviteEmail("");
      fetchLeagues(); // Refresh league data
    } catch (error) {
      console.error("Error inviting member:", error);
    }
  };
  useEffect(() => {
    if (!game) {
      console.warn("Game not found.");
    }
  }, [game]);  // ✅ Use 'game' from context instead

  useEffect(() => {
    async function fetchUserData() {
      if (!user) {
        setTeam([]);
        setLeagues([]);
        setUserRanking(null);
        setUserBank(1000000);
        setUsername("Player");
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setTeam(userData.team || []);
          setLeagues(userData.leagues || []);
          setUserRanking(userData.ranking || null);
          setUserBank(userData.bank || 1000000); // Default $1M
          setUsername(userData.username || "Player");
        } else {
          console.warn("User data not found.");
          setTeam([]);
          setLeagues([]);
          setUserRanking(null);
          setUserBank(1000000);
          setUsername("Player");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setTeam([]);
        setLeagues([]);
        setUserRanking(null);
        setUserBank(1000000);
        setUsername("Player");
      }
    }

    fetchUserData();
  }, [user]); // ✅ Now properly inside useEffect

  // ✅ Fetch user's team
  useEffect(() => {
    async function fetchTeam() {
      if (!user) return;

      try {
        const teamDoc = await getDoc(doc(db, "teams", user.uid));
        if (teamDoc.exists()) {
          const teamData = teamDoc.data().team; // Racer IDs
          const racerDocs = await getDocs(collection(db, "racers"));
          const racersList = racerDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));

          // Retrieve full racer objects from Firestore
          const fullTeam = racersList.filter(racer => teamData.includes(racer.id));
          setTeam(fullTeam);
        }
      } catch (error) {
        console.error("Error fetching team:", error);
      }
    }
    fetchTeam();
  }, [user]);
  useEffect(() => {
    fetchLeagues();
  }, [user, leagues]); // ✅ Ensure it runs when leagues update

  if (game === null) return <p>Game not found.</p>;
  if (!game) return <p>Loading...</p>; // Prevents infinite loading

  return (
    <div
      className="homepage-container"
      style={{
        backgroundImage: `url(${game.cover})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Event Logo */}
      <div className="event-logo-container">
        <img src={game.logo} alt={game.name} className="event-logo" style={{ width: "400px" }} />
      </div>


      <div className="page-container" style={{ backgroundColor: "white", maxWidth: "1024px", marginTop: "20px", borderRadius: "10px", height: "100%", marginBottom: "20px" }}>
        {user && (
          <div className="user-greeting">
            <h2>Welcome, {username}!</h2>
            <p>Your Ranking: {userRanking || "Unranked"} | 🏆 Score: {userRanking || 0} | 💰 Bank: ${userBank ? userBank.toLocaleString() : "1M"}</p>
          </div>
        )}
        <div className="rules-container" style={{ padding: "20px", textAlign: "left" }}>
          <h1>How It Works!</h1>
          <p>
            Create your own Mountain Bike Fantasy League team,
            <ul>
              <li>Your team will score points depending on how your racers performed at each round.</li>
              <li>You have $1,000,000 (in monkey-money) to recruit your racers. Edit your team after each round.</li>
              <li>Predict the fastest racers to score the most points and earn your community's respect.</li>
              <li>Invite your friends to a Mini League to compete against them AND other mini-leagues.</li>
            </ul>
          </p>
        </div>

        {/* Actions Row */}
        <div className="actions-row">
  <div className="action-box">
    <img src="/img/icon_home_team.png" alt="Team Icon" className="action-icon" />
    <h3>1. Pick Your Team</h3>
    <p>6 racers max, <br/>2 female minimum. <br/>1 junior minimum. <br/>Teams are locked 12 hours before the event.</p>
    {user ? (
      <button className="secondary-btn" onClick={() => navigate("/team-setup")}>
        {team.length === 0 ? "Create Your Team" : "Edit Your Team"}
      </button>
    ) : (
      <button className="secondary-btn" onClick={() => setShowLoginModal(true)}>
        Sign In to Play
      </button>
    )}
  </div>

  <div className="action-box">
    <img src="/img/icon_home_league.png" alt="League Icon" className="action-icon" />
    <h3>2. Create a Mini League!</h3>
    <p>(Optional) <br/>Compete with your friends!</p>
    <button
      className="secondary-btn"
      disabled={team.length === 0}
      onClick={() => setShowLeagueModal(true)}
    >
      {team.length === 0 ? "Pick Team First" : "Create a League"}
    </button>
  </div>

  <div className="action-box">
    <img src="/img/icon_home_trophy.png" alt="Win Icon" className="action-icon" />
    <h3>3. Win!</h3>
    <p>Bragging rights about your MTB-ology and maybe some surprises!</p>
  </div>
</div>
<div className="team-container">
<h2>Your Team</h2>
<MyTeam team={team} userBank={userBank} />
</div>

        {/* User Stats Section */}
        {user && (

          <div className="league-container">
  <h2>Your Leagues</h2>
  {leagues.length > 0 ? (
    leagues.map((league) => (
      <div key={league.id} className="league-item">
        <div style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center"
        }}><h3>{league.name} (🏆 {league.totalPoints} pts)</h3>         
        {/* ✅ Delete button for owner */}
        {league.owner === user.email && (
          <button  onClick={() => handleDeleteLeague(league.id)}
            style={{ marginLeft: "10px",
            backgroundColor: "transparent",
            border: "none",
            color: "grey",
            cursor: "pointer",}} 
            >
            <FaTrash/>
          </button>
        )}
        </div>
        {/* ✅ Display Members */}
        <h4>Members:</h4>
        <ul>
          {league.members.map((member, index) => (
            <li key={index}>
              <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
              {member.email} ({member.status}) 
              {member.status === "accepted" && ` - 🏆 ${member.score} pts`}
              {/* ✅ Leave button for members */}
              {member.email === user.email && (
                <button  onClick={() => handleLeaveLeague(league.id)}
                style={{ marginLeft: "10px",
            backgroundColor: "transparent",
            border: "none",
            color: "grey",
            cursor: "pointer",fontSize: "1.2em"}} >
                  
                  <FaDoorOpen/>
                </button>
              )}
              </div>
            </li>
          ))}
        </ul>

        {/* ✅ Display Invited Users */}
        {league.invited && league.invited.length > 0 && (
          <>
            <h4>Invited Members:</h4>
            <ul>
              {league.invited.map((invite, index) => (
                <li key={index}>
                  {invite.email} (Pending Invitation)
                  {/* ✅ Accept/Dismiss buttons for invited users */}
                  {invite.email === user.email && (
                    <>
                      <button className="accept-btn" onClick={() => handleAcceptInvite(league.id)}>
                        Accept
                      </button>
                      <button className="dismiss-btn" onClick={() => handleDismissInvite(league.id)}>
                        Dismiss
                      </button>
                    </>
                  )}
                </li>
              ))}
            </ul>
          </>
        )}



        {/* ✅ Invite More Members */}
        <button className="secondary-btn" onClick={() => { setSelectedLeague(league); setShowInviteModal(true); }}>
          Invite Member
        </button>
        <br/><br/><hr/>
      </div>
    ))
  ) : (
    <p>No Leagues yet.</p>
  )}


    {/* Create League Modal */}
    {showLeagueModal && (
      <div className="modal-overlay">
        <div className="modal">
          <button className="modal-close-btn" onClick={() => setShowLeagueModal(false)}>✖</button>
          <h3>Create a League</h3>
          <input type="text" placeholder="League Name" value={leagueName} onChange={(e) => setLeagueName(e.target.value)} className="input-field" />
          <button className="secondary-btn" onClick={handleCreateLeague} 
          style={{ marginLeft: "10px" }}>Create League</button>
        </div>
      </div>
    )}

    {/* Invite Member Modal */}
    {showInviteModal && (
      <div className="modal-overlay">
        <div className="modal">
          <button className="modal-close-btn" onClick={() => setShowInviteModal(false)}>✖</button>
          <h3>Invite Member</h3>
          <input type="email" placeholder="Guest Email" value={inviteEmail} onChange={(e) => setInviteEmail(e.target.value)} className="input-field" />
          <button className="secondary-btn" onClick={handleInviteMember}
          style={{ marginLeft: "10px" }}>Invite</button>
        </div>
      </div>
    )}

          </div>
        )}
      </div>
      {/* Login Modal */}
      {showLoginModal && <Login onClose={() => setShowLoginModal(false)} />}
    </div>
  );
};

export default HomePage;