import UploadRacers from "../utils/uploadRacers";

const AdminPage = () => {
  return (
    <div>
      <h1>Admin Dashboard</h1>
      <UploadRacers />
    </div>
  );
};

export default AdminPage;