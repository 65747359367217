import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import Login from "./Login";
import { useNavigate } from "react-router-dom";

import "../App.css";

function Header() {
  const { user, logout } = useAuth();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/"); // ✅ Now inside a Router component
  };

  return (
    <header className="header-container">
      <div className="header-left">
        <a href="/">
          <img src="/img/logo_fantasyLeague.png" alt="Fantasy League Logo" className="header-logo" />
        </a>
      </div>

      <nav className="header-nav">
        <a href="/">Home</a>
        <a href="/view-racers">View Racers</a>
        <a href="/">View Events</a>
        <a href="/players-ranking">Player Ranking</a>
        <a href="/leagues-ranking">Leagues Ranking</a>
        <a href="/about">About</a>
      </nav>

      <div className="header-right">
        {user ? (
          <button className="logout-btn" onClick={handleLogout}>Logout</button>
        ) : (
          <button className="secondary-btn" onClick={() => setShowLoginModal(true)}>Login</button>
        )}
      </div>

      {/* Login Modal */}
      {showLoginModal && <Login onClose={() => setShowLoginModal(false)} />}
    </header>
  );
}

export default Header;