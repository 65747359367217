import React from "react";
import "../App.css";

const RacerThumb = ({ racer, onSelect, onRemove, isSelected, editMode }) => {
  return (
    <div className={`racer-thumb ${isSelected ? "selected" : ""}`}>
      {/* Remove button as small cross */}
      {isSelected && editMode &&(
        <button className="remove-cross" onClick={() => onRemove(racer)}>✖</button>
      )}
      
      <p className="racer-category">{racer.category}</p>
      <img src={racer.picture ? `/img/racer_portrait/${racer.picture}` : "/img/racer_portrait/placeholder.png"} 
           alt={racer.lastName} 
           className="racer-img" />
      <p>{racer.firstName} <br/><span style={{ fontWeight: 'bold' }}>{racer.lastName}</span></p>

      {!isSelected && (
        <button className="select-btn" onClick={() => onSelect(racer)}>Select</button>
      )}
    </div>
  );
};

export default RacerThumb;