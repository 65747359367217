import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useGame } from "../contexts/GameContext";
import "../App.css";

const PlayerRanking = () => {
  const { game } = useGame();
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    async function fetchPlayers() {
      const playersSnapshot = await getDocs(collection(db, "users"));
      const playersList = playersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Sort players by their ranking points (highest first)
      setPlayers(playersList.sort((a, b) => (b.points || 0) - (a.points || 0)));
    }
    fetchPlayers();
  }, []);

  if (!game) return <p>Loading game data...</p>;

  return (
    <div className="homepage-container" style={{ backgroundImage: `url(${game.cover})`, backgroundSize: "cover" }}>
      <div className="event-logo-container">
        <img src={game.logo} alt={game.name} className="event-logo" />
      </div>

      <div className="page-container"
      style={{ 
        backgroundColor: "rgba(255, 255, 255, 1)", 
        padding: "0px", 
        borderRadius: "10px",
        marginTop: "20px",
        color: "black",
         }}
      >
        <h2>Player Rankings</h2>
        <table className="ranking-table"
        style={{
          width: "100%",
          textAlign: "center",
          margin: "20px",
        }}>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Username</th>
              <th>Points</th>
            </tr>
          </thead>
          <tbody>
            {players.map((player, index) => (
              <tr key={player.id}>
                <td>{index + 1}</td>
                <td>{player.username || "Anonymous"}</td>
                <td>{player.points || 0}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlayerRanking;