import { useEffect, useState } from "react";
// import { fetchRacerData } from "./racerAPI"; // AI-powered data retrieval

const RacerInfo = ({ racer, onClose }) => {
  const [info, setInfo] = useState("Loading...");

//   useEffect(() => {
//     async function loadRacerData() {
//       const summary = await fetchRacerData(racer.name);
//       setInfo(summary);
//     }
//     loadRacerData();
//   }, [racer]);

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>{racer.name}</h2>
        <p>{info}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default RacerInfo;