// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB6eLOuGGANq5EB3yOB4gccs9uB0NiMwCQ",
  authDomain: "mtb-fantasy-league-e786a.firebaseapp.com",
  projectId: "mtb-fantasy-league-e786a",
  storageBucket: "mtb-fantasy-league-e786a.firebasestorage.app",
  messagingSenderId: "1088391671277",
  appId: "1:1088391671277:web:b723f0e814e62847640be4",
  measurementId: "G-GJBY38EDX2"
};

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics
const analytics = getAnalytics(app);

// Export the Auth instance
export const auth = getAuth(app);

// Export the Firestore DB
export const db = getFirestore(app);



export const storage = getStorage(app);