import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css'; // Ensure you have a corresponding CSS file

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-top">
        <div className="footer-column">
          <img src="/img/logo_fantasyLeague.png" alt="Fantasy League Logo" className="footer-logo" />
          <span>MTB Fantasy League - Who's going to win?</span>
          <span>Contact: <a href="mailto:contact@victorise.com">contact@victorise.com</a></span>
          <span>© 2025 Victorise. All rights reserved.</span>
        </div>
        <div className="footer-column">
          <h4>Navigation</h4>
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/view-racers">Racers</Link>
          <Link to="/players-ranking">Player Leaderboards</Link>
          <Link to="/leagues-ranking">Leagues Ranking</Link>
          <Link to="/tos">Terms of Service</Link> 
          <Link to="/privacypolicy">Privacy Policy</Link>
        </div>
        <div className="footer-column">
          <h4>Other Games</h4>
          <Link to="/">UCI World Cup DH 2024</Link>
          <Link to="/">UCI World Cup XC 2024</Link>
          <Link to="/">Monster Energy Pro Downhill 2024</Link>
          <Link to="/">UCI XC World Cup 2023 (Archives)</Link>
        </div>
      </div>

    </footer>
  );
};

export default Footer;