import React from "react";
import '../App.css';

const TermsOfService = () => {
  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "auto", backgroundColor: "white"  }}>
      <h1>Terms of Service</h1>
      <p>Last Updated: 01/01/25</p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing or using MTBFantasyLeaggue, you agree to be bound by these Terms of Service and all applicable laws and regulations.
        If you do not agree with any of these terms, you are prohibited from using or accessing this site or any of our services.
      </p>

      <h2>2. Description of Services</h2>
<p>
  MTB Fantasy League is a fantasy sports platform where users predict race outcomes and build teams based on professional mountain bike events. 
  Participants compete for points, rankings, and prizes based on strategic selections. 
  We reserve the right to modify, suspend, or discontinue any part of the service at any time without prior notice.
</p>

      <h2>3. User Accounts and Security</h2>
      <p>
        When creating an account, you agree to provide accurate and complete information. 
        You are responsible for maintaining the confidentiality of your account credentials and are fully responsible for all activities that occur under your account.
        You agree to notify us immediately of any unauthorized use or security breach.
      </p>

      <h2>4. User Conduct & Fair Play</h2>
<p>By using MTB Fantasy League, you agree not to:</p>
<ul>
  <li>Use automated bots or software to manipulate results.</li>
  <li>Create multiple accounts for unfair advantage.</li>
  <li>Exploit loopholes, glitches, or errors in the platform.</li>
  <li>Violate any local, state, or national laws regarding fantasy sports participation.</li>
</ul>
<p>Violations may result in <strong>account suspension, prize forfeiture, or legal action</strong>.</p>

<h2>5. Intellectual Property</h2>
<p>
  All content, graphics, and code on MTB Fantasy League are <strong>owned or licensed</strong> by us. Users may not copy, distribute, or modify platform materials without explicit permission.
</p>

      <h2>6. User-Generated Content</h2>
      <p>
        By uploading content (videos, telemetry data, or other materials) to MTBFantasyLeaggue, you grant us a worldwide, non-exclusive, royalty-free license to store, process, and display your content as part of our services.
        You retain ownership of your content, but you confirm that you have the necessary rights and permissions to upload it.
      </p>

      <h2>7. Privacy and Data Protection</h2>
      <p>
        Your use of our services is also governed by our <a href="/privacypolicy">Privacy Policy</a>. 
        By using MTBFantasyLeaggue, you acknowledge and agree that we may collect, store, and process your personal and telemetry data in accordance with our Privacy Policy.
      </p>
      <h2>8. Entry Fees, Prizes & Payouts</h2>
<ul>
  <li>Some contests may <strong>require an entry fee</strong>.</li>
  <li>Prizes will be awarded based on contest rules and leaderboard standings.</li>
  <li>All taxes and reporting obligations on winnings are the responsibility of the user.</li>
  <li>Refunds are <strong>not issued</strong> unless a contest is canceled.</li>
</ul>

      <h2>9. Service Availability and Limitations</h2>
      <p>
        While we strive to provide uninterrupted service, we do not guarantee that MTBFantasyLeaggue will always be available or error-free.
        We may perform maintenance or updates that could temporarily impact service availability.
      </p>

      <h2>10. Termination</h2>
      <p>
        We reserve the right to suspend or terminate your account if you violate these Terms of Service, engage in abusive behavior, or use our platform for unlawful purposes.
        You may also terminate your account at any time by contacting our support team.
      </p>

      <h2>11. Disclaimers & Liability Limitations</h2>
<p>
  MTB Fantasy League is provided <strong>\"as is\"</strong> and <strong>\"as available\"</strong>. We do not guarantee uninterrupted service or error-free results.
</p>
<p>
  To the fullest extent permitted by law, MTB Fantasy League is <strong>not responsible for:</strong>
</p>
<ul>
  <li>Race cancellations or schedule changes affecting fantasy contests.</li>
  <li>Errors in official race data, results, or rankings.</li>
  <li>Financial losses from contest participation.</li>
</ul>

      <h2>12. Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless MTBFantasyLeaggue, its employees, contractors, and affiliates from any claims, damages, losses, or liabilities arising from your use of our services or violation of these terms.
      </p>

      <h2>13. Governing Law and Dispute Resolution</h2>
      <p>
        These Terms of Service shall be governed by and construed in accordance with the laws of the United States of America.
        Any disputes arising from these terms shall be resolved through arbitration or mediation before resorting to litigation.
      </p>

      <h2>14. Changes to Terms</h2>
      <p>
        We may update these Terms of Service from time to time. If we make significant changes, we will notify users via email or a notification on our website.
        Your continued use of MTBFantasyLeaggue after such updates constitutes acceptance of the new terms.
      </p>

      <h2>15. Contact Us</h2>
      <p>
        If you have any questions about these Terms of Service, please contact us at <a href="mailto:contact@MTBFantasyLeaggue.com">contact@MTBFantasyLeaggue.com</a>.
      </p>
    </div>
  );
};

export default TermsOfService;