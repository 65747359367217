import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { GameProvider } from "./contexts/GameContext";
import Header from './components/Header';
import Footer from './components/Footer';
import SplashPage from './pages/SplashPage';
import HomePage from './pages/HomePage'; // For game pages
import TeamCreation from "./pages/TeamCreation";
import AdminPage from './pages/AdminPage';
import LeaguesRanking  from './pages/LeaguesRanking';
import PlayerRanking from './pages/PlayerRanking';
import About from './pages/About';
import ViewRacers from './pages/ViewRacers';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  return (
    <AuthProvider>
       <GameProvider>
      <BrowserRouter>
        <div className="app-container">
          <Header />
          <div className="main-content">
          <Routes>
          <Route path="/" element={<SplashPage />} />
          <Route path="/game/:id" element={<HomePage />} />
          <Route path="/team-setup" element={<TeamCreation />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/leagues-ranking" element={<LeaguesRanking />} />
          <Route path="/players-ranking" element={<PlayerRanking />} />
          <Route path="/about" element={<About />} />
          <Route path="/view-racers" element={<ViewRacers />} />
          <Route path="/tos" element={<TermsOfService />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="*" element={<SplashPage />} />
        </Routes>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
      </GameProvider>
    </AuthProvider>
  );
}

export default App;