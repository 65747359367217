import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useGame } from "../contexts/GameContext";
import "../App.css";

const ViewRacers = () => {
  const { game } = useGame();
  const [racers, setRacers] = useState([]);
  const [filteredRacers, setFilteredRacers] = useState([]);
  const [filter, setFilter] = useState("Pro Men");

  useEffect(() => {
    async function fetchRacers() {
      const racersSnapshot = await getDocs(collection(db, "racers"));
      const racersList = racersSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => a.rank2024 - b.rank2024); // ✅ Sort by rank2024

      setRacers(racersList);
      setFilteredRacers(racersList.filter(r => r.category === filter));
    }
    fetchRacers();
  }, []);

  const handleFilterChange = (category) => {
    setFilter(category);
    setFilteredRacers(racers.filter(racer => racer.category === category));
  };

  if (!game) return <p>Loading game data...</p>;

  return (
    <div className="homepage-container" style={{ backgroundImage: `url(${game.cover})`, backgroundSize: "cover" }}>
      <div className="event-logo-container">
        <img src={game.logo} alt={game.name} className="event-logo" />
      </div>

      <div className="page-container"       
      style={{ 
        backgroundColor: "rgba(255, 255, 255, 1)", 
        padding: "0px", 
        borderRadius: "10px",
        marginTop: "20px",
        color: "black",
         }}>
        <h2>View Racers</h2>

        {/* Filter Tabs */}
        <div className="filter-tabs">
          {["Pro Men", "Pro Women", "17-18 Men", "17-18 Women"].map(category => (
            <button key={category} onClick={() => handleFilterChange(category)} className={filter === category ? "active" : ""}>
              {category}
            </button>
          ))}
        </div>

        {/* Racer List */}
        <table className="racers-table"
        
        style={{
     color: "WHITE",
        }}>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>Name</th>
                
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {filteredRacers.map((racer) => (
                <tr key={racer.id}>
                  <td>
                    <img 
                      src={`/img/racer_portrait/${racer.picture}`} 
                      alt={racer.firstName} 
                      className="racer-portrait" 
                    />
                  </td>
                  <td style={{ textAlign: "left" }}>#{racer.rank2024} </td>
                  <td style={{ textAlign: "left" }}>{racer.firstName} {racer.lastName}</td>
                  <td>
${racer.price.toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
      </div>
    </div>
  );
};

export default ViewRacers;