import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase";
import { collection, getDocs, doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom"; // ✅ Redirect after saving
import MyTeam from "../components/MyTeam";
import { useGame } from "../contexts/GameContext";
import RacerInfo from "../components/RacerInfo";

import "../App.css";

const TeamCreation = () => {
  const { game } = useGame();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [team, setTeam] = useState([]);
  const [racers, setRacers] = useState([]);
  const [filteredRacers, setFilteredRacers] = useState([]);
  const [userBank, setUserBank] = useState(1000000);
  const [filter, setFilter] = useState("Pro Men");
  const [username, setUsername] = useState(null);
  const [userRanking, setUserRanking] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedRacer, setSelectedRacer] = useState(null);
  const [requirementsMet, setRequirementsMet] = useState({
    sixRiders: false,
    twoFemales: false,
    oneJunior: false,
  });
  

  // ✅ Fetch user data (Check if team exists)
  useEffect(() => {
    async function fetchUserData() {
      if (!user) return;

      try {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setTeam(userData.team || []);
          setUserBank(userData.bank || 1000000);
          setUsername(userData.username || "Player");
          setUserRanking(userData.ranking || null);
          setIsEditing(!!userData.team?.length); // ✅ If team exists, enable edit mode
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
    fetchUserData();
  }, [user]);

  // ✅ Fetch racers from Firestore
  useEffect(() => {
    async function fetchRacers() {
      const racersSnapshot = await getDocs(collection(db, "racers"));
      let racersData = racersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      racersData = racersData.sort((a, b) => a.rank2024 - b.rank2024);
  
      setRacers(racersData);
      setFilteredRacers(racersData.filter(r => r.category === filter));
    }
    fetchRacers();
  }, []);

  // ✅ Check requirements dynamically
  useEffect(() => {
    const femaleCategories = ["Pro Women", "17-18 Women"];
    const juniorCategories = ["17-18 Men", "17-18 Women"];

    const femaleCount = team.filter(racer => femaleCategories.includes(racer.category)).length;
    const juniorCount = team.filter(racer => juniorCategories.includes(racer.category)).length;

    setRequirementsMet({
      sixRiders: team.length === 6,
      twoFemales: femaleCount >= 2,
      oneJunior: juniorCount >= 1,
    });
  }, [team]);
     useEffect(() => {
      async function fetchTeam() {
        if (!user) return;
  
        try {
          const teamDoc = await getDoc(doc(db, "teams", user.uid));
          if (teamDoc.exists()) {
            const teamData = teamDoc.data().team; // Racer IDs
            const racerDocs = await getDocs(collection(db, "racers"));
            const racersList = racerDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
            // Retrieve full racer objects from Firestore
            const fullTeam = racersList.filter(racer => teamData.includes(racer.id));
            setTeam(fullTeam);
          }
        } catch (error) {
          console.error("Error fetching team:", error);
        }
      }
      fetchTeam();
    }, [user]);

  const handleFilterChange = (category) => {
    setFilter(category);
    setFilteredRacers(racers.filter(racer => racer.category === category));
  };

  const handleSelectRacer = (racer) => {
    if (team.length < 6 && userBank >= racer.price) {
      setTeam([...team, racer]);
      setUserBank(userBank - racer.price);
    }
  };

  const handleRemoveRacer = (racer) => {
    setTeam(team.filter(r => r.id !== racer.id));
    setUserBank(userBank + racer.price);
  };

  const handleSaveTeam = async () => {
    if (!user) return;

    const teamData = {
      ownerId: user.uid,
      team: team.map(racer => racer.id), // ✅ Save only racer IDs
    };

    try {
      await setDoc(doc(db, "teams", user.uid), teamData);
      alert("Team saved successfully!");
      navigate(`/game/${game.id}`);
    } catch (error) {
      console.error("Error saving team:", error);
      alert("Failed to save team.");
    }
  };

  const totalSpent = 1000000 - userBank;
  const allRequirementsMet = Object.values(requirementsMet).every(Boolean);

  if (!game) return <p>Loading game data...</p>;

  return (
    <div
      className="homepage-container"
      style={{
        backgroundImage: `url(${game.cover})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Event Logo */}
      <div className="event-logo-container">
        <img src={game.logo} alt={game.name} className="event-logo" style={{ width: "400px" }} />
      </div>

      <div className="page-container" 
      style={{ 
        backgroundColor: "rgba(255, 255, 255, 1)", 
        padding: "0px", 
        borderRadius: "10px",
        marginTop: "20px",
         }}>
  

        <MyTeam team={team} onRemove={handleRemoveRacer} editMode="true" />

        {/* Budget & Requirements Section in a Row */}
        <div className="team-requirements-container" 
>

          <div className="team-requirements" >
          <div>🏦 Bank: ${userBank.toLocaleString()} </div>
            <div>{requirementsMet.sixRiders ? "✅" : "❌"} 6 Riders</div>
            <div>{requirementsMet.twoFemales ? "✅" : "❌"} At least 2 Female Riders</div>
            <div>{requirementsMet.oneJunior ? "✅" : "❌"} At least 1 Junior Rider</div>
          </div>
          <button 
            className="primary-btn" 
            onClick={handleSaveTeam} 
            disabled={!allRequirementsMet}
          >
            {isEditing ? "Edit Team" : "Save Team"}
          </button>
        </div>

        {/* Filter Tabs */}
        <div className="filter-tabs">
          {["Pro Men", "Pro Women", "17-18 Men", "17-18 Women"].map(category => (
            <button 
              key={category} 
              onClick={() => handleFilterChange(category)} 
              className={filter === category ? "active" : ""}
            >
              {category}
            </button>
          ))}
        </div>

        {/* Racer Table */}
        <div className="racers-table-container">
          <table className="racers-table">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th></th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {filteredRacers.map((racer) => (
                <tr key={racer.id}>
                  <td>
                    <img 
                      src={`/img/racer_portrait/${racer.picture}`} 
                      alt={racer.firstName} 
                      className="racer-portrait" 
                    />
                  </td>
                  <td style={{ textAlign: "left" }}>{racer.firstName} {racer.lastName}</td>
                  <td>
                    {/* <button onClick={() => setSelectedRacer(racer)}>More Info</button> */}
                    </td>
                  <td>
                    <button 
                      className="select-button" 
                      onClick={() => handleSelectRacer(racer)}
                      disabled={team.some(r => r.id === racer.id) || userBank < racer.price}
                    >
                      {team.some(r => r.id === racer.id) ? "Selected" : ""} ${racer.price.toLocaleString()}
                    </button>
                    
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {selectedRacer && (
        <RacerInfo racer={selectedRacer} onClose={() => setSelectedRacer(null)} />
      )}

        </div>
      </div>
    </div>
  );
};

export default TeamCreation;