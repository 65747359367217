const gamesData = [
    // Existing Games
     // New Promonster DH Series 2024
     {
        id: "5",
        name: "PDS 1: Ride Rock Creek, NC",
        cover: "/img/pds1_cover.png",
        logo: "/img/pds1_logo.png",
        description: "Apr 3 - 6 | The opening race of the  Monster Energy Pro Downhill Series.",
        status: "open",
      },
      {
        id: "6",
        name: "PDS 2: Mountain Creek, NJ",
        cover: "/img/pds1_cover.png",
        logo: "/img/pds2_logo.png",
        description: "May 8 - 11 | The second stop of the Monster Energy Pro Downhill Series.",
        status: "closed",
      },
      {
        id: "7",
        name: "PDS 3: Solitude Mountain, UT",
        cover: "/img/pds1_cover.png",
        logo: "/img/pds3_logo.png",
        description: "Jul 24 - 27 | Mid-season showdown in Utah.",
        status: "closed",
      },
      {
        id: "8",
        name: "PDS 4: Big Bear Lake, CA (USAC Nationals)",
        cover: "/img/pds1_cover.png",
        logo: "/img/pds4_logo.png",
        description: "Jul 31 - Aug 3 | California stop for the  Monster Energy Pro Downhill Series and USAC National Championship.",
        status: "closed",
      },
      {
        id: "9",
        name: "PDS 5: Killington Resort, VT (Fox US OPEN)",
        cover: "/img/pds1_cover.png",
        logo: "/img/pds5_logo.png",
        description: "Sep 25 - 28 | The final round at the  Monster Energy Pro Downhill Series at the  Fox US OPEN.",
        status: "closed",
      },
    {
      id: "1",
      name: "UCI DH Worldcup 2024",
      cover: "/img/2024DH_img.jpg",
      logo: "/img/logo/2024DH_logo.png",
      description: "UCI Downill Worldcup 2023",
      status: "archived",
    },
    {
      id: "2",
      name: "UCI WORLDS 2023",
      cover: "/img/DHMTB_WC_background.png",
      logo: "/img/logo/Archives_logo.png",
      description: "UCI Worldchampionships 2023",
      status: "archived",
    },
    {
      id: "3",
      name: "Red Bull Rampage 2024",
      cover: "/img/rampage_background.png",
      logo: "/img/rampage_logo.png",
      description: "The ultimate freeride challenge.",
      status: "archived",
    },
    {
      id: "4",
      name: "UCI DH Worldcup 2023 (Archived)",
      cover: "/img/2024Archives_img.jpg",
      logo: "/img/logo/Archives_logo.png",
      description: "UCI Cross Country Worldcup 2023",
      status: "archived",
    },
  
   
  
    // Archived Events
    {
      id: "10",
      name: "UCI DH Worldcup 2023 (Archived)",
      cover: "/img/DHMTB_background.png",
      logo: "/img/logo/Archives_logo.png",
      description: "UCI Downill Worldcup 2023",
      status: "archived",
    },
    {
      id: "11",
      name: "UCI DH Worldcup 2022 (Archived)",
      cover: "/img/_accountBackground.png",
      logo: "/img/logo/Archives_logo.png",
      description: "UCI Downill Worldcup 2022",
      status: "archived",
    },
    {
      id: "12",
      name: "UCI XC Worldcup 2022 (Archived)",
      cover: "/img/_accountBackground.png",
      logo: "/img/logo/Archives_logo.png",
      description: "UCI Cross Country Worldcup 2022",
      status: "archived",
    },
  ];
  
  export default gamesData;