import React from "react";
import { useGame } from "../contexts/GameContext";
import { useNavigate } from "react-router-dom";
import gamesData from "../data/games";
import "../App.css";

const SplashPage = () => {
  const { setGame } = useGame();
  const navigate = useNavigate();

  const handleGameSelect = (game) => {
    if (game.status !== "open") return; // Prevent navigation if the game is closed or archived
    setGame(game);
    navigate(`/game/${game.id}`);
  };

  return (
    <div className="splash-container">
      <div className="games-grid">
        {gamesData.map((game) => (
          <div
            key={game.id}
            className={`game-card ${game.status !== "open" ? "disabled" : ""}`} 
            style={{ backgroundImage: `url(${game.cover})` }}
            onClick={game.status === "open" ? () => handleGameSelect(game) : undefined} 
          >
            <div className="overlay">
              <img src={game.logo} alt={game.name} className="game-logo" 
              />
              <p>{game.description}</p>
                            {/* ✅ Show Enter button only for open events */}
                            {game.status === "open" && (
                <button className="primary-btn" onClick={() => handleGameSelect(game)}>
                  Enter Event
                </button>
              )}<br/>
              <p className={`game-status ${game.status}`}>
                {game.status === "open" && <span>✅ Open</span>}
                {game.status === "closed" && (
                  <span>
                    📅 Closed
                  </span>
                )}
                {game.status === "archived" && (
                  <span>
                    📂 Archived
                  </span>
                )}
              </p>


            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SplashPage;