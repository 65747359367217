import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { FaUser, FaUserPlus } from "react-icons/fa";
import "../App.css";

const Login = ({ onClose }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");

  if (isAuthenticated) {
    return <Navigate to="/homepage" />;
  }

  const handleSignUp = async () => {
    setError("");
    if (!email || !password) {
      setError("Email and password are required");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(db, "users", user.uid), {
        email,
        username,
        accepted: true,
        credits: 100,
      });

      setIsSignUp(false);
      setEmail("");
      setPassword("");
      setIsAuthenticated(true);
      if (onClose) onClose(); // Close modal on success
    } catch (err) {
      console.error("Error creating account:", err);
      setError(err.message);
    }
  };

  const handleLogin = async () => {
    setError("");
    if (!email || !password) {
      setError("Email and password are required");
      return;
    }
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        setError("No user profile found. Contact admin.");
        return;
      }

      const userData = docSnap.data();
      if (userData.accepted) {
        setIsAuthenticated(true);
        if (onClose) onClose(); // Close modal on success
      } else {
        setError("Account not accepted yet. Please contact admin.");
      }
    } catch (err) {
      console.error("Error logging in:", err);
      setError(err.message);
    }
  };

  return (
    <div className="login-modal">
      <div className="login-box">
        <button className="close-modal" onClick={onClose}>✖</button>
        <div className="login-header">
          {isSignUp ? <FaUserPlus className="login-icon" /> : <FaUser className="login-icon" />}
          <h2>{isSignUp ? "Create Your Account" : "Login to Your Account"}</h2>
        </div>

        {error && <p className="error-text">{error}</p>}

        {isSignUp && (
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="login-input"
          />
        )}

        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="login-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="login-input"
        />

        {isSignUp ? (
          <button onClick={handleSignUp} className="primary-btn login-btn">
            Sign Up
          </button>
        ) : (
          <button onClick={handleLogin} className="primary-btn login-btn">
            Login
          </button>
        )}

        <p className="login-switch">
          {isSignUp ? "Already have an account?" : "No account yet?"}{" "}
          <span className="login-switch-link" onClick={() => setIsSignUp(!isSignUp)}>
            {isSignUp ? "Login" : "Create an account"}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;