import React from "react";
import RacerThumb from "./RacerThumb";
import "../App.css";

const MyTeam = ({ team, onRemove, editMode }) => {
  return (
    <div className="team-display">
      <div className="team-grid">
        {[...Array(6)].map((_, index) => {
          const racer = team[index];
          return (
            <div key={index} className="team-member">
              {racer ? (
                <RacerThumb racer={racer} onRemove={onRemove} editMode={editMode} isSelected={true} />
              ) : (
                <div className="empty-slot"> ? </div> // Grey placeholder
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MyTeam;