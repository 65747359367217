import React from "react";
import '../App.css';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "auto", backgroundColor: "white" }}>
      <h1>Privacy Policy</h1>
      <p>Last Updated: 01/01/25</p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to Victorise. We respect your privacy and are committed to protecting it. 
        This Privacy Policy explains how we collect, use, share, and safeguard your information when you use our services.
      </p>

      <h2>2. Information We Collect</h2>
      <p>We may collect the following types of information:</p>
      <ul>
        <li><strong>Personal Information:</strong> Name, email address, and contact details when you sign up.</li>
        <li><strong>Usage Data:</strong> Information about your activity on our platform, such as uploaded videos, telemetry data, and interactions.</li>
        <li><strong>Device Information:</strong> Browser type, IP address, and device identifiers.</li>
        <li><strong>Cookies & Tracking Technologies:</strong> We use cookies to enhance user experience and analyze traffic.</li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <p>We use your information to:</p>
      <ul>
        <li>Provide and maintain our services.</li>
        <li>Process transactions and account registrations.</li>
        <li>Analyze usage trends and improve our platform.</li>
        <li>Ensure security and prevent fraudulent activities.</li>
        <li>Comply with legal obligations.</li>
      </ul>

      <h2>4. Data Security</h2>
      <p>
        We implement industry-standard security measures to protect your personal information from unauthorized access, alteration, or disclosure.
        However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.
      </p>

      <h2>5. Data Sharing and Third-Party Services</h2>
      <p>
        We do not sell or rent your personal data. However, we may share information in the following cases:
      </p>
      <ul>
        <li><strong>Service Providers:</strong> We may share data with third-party vendors that assist us in providing our services (e.g., payment processors, cloud storage providers).</li>
        <li><strong>Legal Compliance:</strong> We may disclose information if required by law or in response to valid legal requests.</li>
        <li><strong>Business Transfers:</strong> If Victorise is involved in a merger, acquisition, or sale of assets, your data may be transferred.</li>
      </ul>

      <h2>6. Your Rights and Choices</h2>
      <p>You have the following rights regarding your personal information:</p>
      <ul>
        <li><strong>Access:</strong> You can request access to the personal data we hold about you.</li>
        <li><strong>Correction:</strong> You may request corrections to inaccurate or incomplete data.</li>
        <li><strong>Deletion:</strong> You can request that we delete your personal data, subject to legal obligations.</li>
        <li><strong>Opt-Out:</strong> You can opt out of marketing emails by using the unsubscribe link in our emails.</li>
      </ul>

      <h2>7. Cookies and Tracking</h2>
      <p>
        We use cookies and similar tracking technologies to enhance your experience. 
        You can adjust your browser settings to disable cookies, but this may affect service functionality.
      </p>

      <h2>8. Retention of Data</h2>
      <p>
        We retain your data for as long as necessary to provide our services and comply with legal obligations.
        When no longer needed, we securely delete or anonymize your information.
      </p>

      <h2>9. Children’s Privacy</h2>
      <p>
        Our services are not intended for users under the age of 13.
        We do not knowingly collect personal data from children. If we become aware of such data, we will take steps to remove it.
      </p>

      <h2>10. International Data Transfers</h2>
      <p>
        If you are accessing our services from outside the United States, note that your information may be transferred and processed in countries where data protection laws may differ.
      </p>

      <h2>11. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. 
        If we make significant changes, we will notify users via email or a notice on our website.
        Your continued use of our services after such updates constitutes acceptance of the revised policy.
      </p>

      <h2>12. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, contact us at 
        <a href="mailto:support@victorise.com">support@victorise.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;