import React from "react";
import { useGame } from "../contexts/GameContext";
import "../App.css";

const About = () => {
  const { game } = useGame();

  if (!game) return <p>Loading game data...</p>;

  return (
    <div className="homepage-container" style={{ backgroundImage: `url(${game.cover})`, backgroundSize: "cover" }}>
      <div className="event-logo-container">
        <img src={game.logo} alt={game.name} className="event-logo" />
      </div>

      <div className="page-container"
        style={{ 
          backgroundColor: "rgba(255, 255, 255, 1)", 
          padding: "20px", 
          borderRadius: "10px",
          marginTop: "20px",
          color: "black",
          textAlign: "left",
          marginBottom: "20px",
        }}
      >
        <h1>About MTB Fantasy League</h1>
        <p>
          Welcome to the **MTB Fantasy League** – the ultimate way to engage with mountain bike racing! 
          Select your dream team of riders, compete in **mini-leagues**, and earn points based on real-world race results.
          Whether you're a hardcore fan or just getting into MTB racing, this fantasy league is your chance to prove your knowledge
          and strategy.
        </p>

        <h2>🏆 What is MTB Fantasy League?</h2>
        <p>
          The MTB Fantasy League is a **virtual competition** where you build a team of real-world MTB racers and earn points
          based on their performance in races. The better your riders perform in actual MTB events, the higher your fantasy points!
        </p>

        <h2>🚴 How It Works</h2>
        <ol>
          <li><strong>Pick Your Team:</strong> Choose 6 riders with a limited budget.</li>
          <li><strong>Follow Real Races:</strong> Your riders will earn points based on real-life results.</li>
          <li><strong>Compete in Leagues:</strong> Join public leagues or create private leagues with friends.</li>
          <li><strong>Win the Season:</strong> The best teams rise in the rankings and claim bragging rights!</li>
        </ol>

        <h2>💰 Budget & Team Selection</h2>
        <p>
          Every player gets a **fixed budget** to select **6 racers**. Each racer has a price based on their past performance and ranking.
          You must balance your selections carefully:
        </p>
        <ul>
          <li>🚀 **Elite riders** cost more but have high point potential.</li>
          <li>🎯 **Underdogs** are cheaper but could be a surprise pick.</li>
          <li>💡 **Strategy matters!** Your team must include **at least 2 female riders** and **1 junior rider**.</li>
        </ul>

        <h2>🔢 Scoring System</h2>
        <p>
          Your fantasy team earns points based on the **actual performance of riders in MTB races**. Points are awarded as follows:
        </p>
        <ul>
          <li>🥇 1st Place = **100 points**</li>
          <li>🥈 2nd Place = **80 points**</li>
          <li>🥉 3rd Place = **65 points**</li>
          <li>🏅 Top 10 Finish = **50 points**</li>
          <li>📈 Finished Race = **10 points**</li>
          <li>💥 Did Not Finish (DNF) = **0 points**</li>
        </ul>
        <p>At the end of each race, your team’s total score is updated!</p>

        <h2>📊 League System</h2>
        <p>
          You can join or create **mini-leagues** to compete with friends or other MTB fans!  
        
        </p>
        <ul>
          <li> Invite friends and battle for the top spot.</li>
        </ul>
        <p>
          Each league’s score is the **average of all accepted members' scores**. The best league wins!
        </p>

        <h2>🛠️ FAQ - Frequently Asked Questions</h2>

        <h3>❓ How do I create a team?</h3>
        <p>Go to the **Team Creation** page and select **6 riders** while staying within your budget.</p>

        <h3>❓ What happens if my rider gets injured?</h3>
        <p>If a rider does not start a race due to injury, they will not earn any points for that event.</p>

        <h3>❓ How often are points updated?</h3>
        <p>Points are updated **after each MTB race**, based on official race results.</p>

        <h3>❓ Can I change my team after I submit it?</h3>
        <p>You can edit your team **until 12 hours before the race starts**. After that, teams are locked.</p>

        <h3>❓ What happens if I go over budget?</h3>
        <p>You cannot save your team if it exceeds the budget. You must adjust your selections.</p>

        <h3>❓ Can I invite friends to my league?</h3>
        <p>Yes! In your **league page**, you can send invitations via email to friends.</p>

        <h3>❓ How do I win?</h3>
        <p>At the end of the season, the player with the highest **total points** wins the league!</p>

        <h3>❓ Is this free to play?</h3>
        <p>Yes! MTB Fantasy League is **completely free** to play and enjoy.</p>

        <h3>❓ Can I play in multiple leagues?</h3>
        <p>Yes! You can join **multiple leagues** and compete in all of them at the same time.</p>

        <h2>🏁 Get Started!</h2>
        <p>
          Ready to prove your **MTB knowledge**? Pick your riders, join a league, and start climbing the leaderboard today!
        </p>
      </div>
    </div>
  );
};

export default About;