import React, { createContext, useContext, useState, useEffect } from "react";

const GameContext = createContext();

export const GameProvider = ({ children }) => {
  const [game, setGame] = useState(null);

  // ✅ Load game from localStorage on first render
  useEffect(() => {
    const storedGame = localStorage.getItem("game");
    if (storedGame) {
      setGame(JSON.parse(storedGame));
    }
  }, []);

  // ✅ Save game to localStorage whenever it changes
  useEffect(() => {
    if (game) {
      localStorage.setItem("game", JSON.stringify(game));
    }
  }, [game]);

  return (
    <GameContext.Provider value={{ game, setGame }}>
      {children}
    </GameContext.Provider>
  );
};

export const useGame = () => useContext(GameContext);